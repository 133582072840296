import { useEffect, useState } from "react";
import logo from '../logo.svg';
import '../App.css';
import { Button } from 'antd';
import 'antd/dist/reset.css';
import { Col, Row } from 'antd';
import Articles from '../components/Articles'
import NewsDays from '../components/NewsDays';
import ArticleMedia from '../components/ArticleMedia';
import { db } from '../firebase'; // update with your path to firestore config
import { collection, getDocs } from "firebase/firestore";
import { generateDates } from "../utilities/dates";
import { DEFAULT_START_DATE, DEFAULT_END_DATE } from '../components/widgets/AntLayout';


function Home() {
  // Get date range from localStorage or use defaults
  const savedRange = localStorage.getItem('searchDateRange');
  const [startDate, endDate] = savedRange 
    ? JSON.parse(savedRange)
    : [DEFAULT_START_DATE, DEFAULT_END_DATE];

  const availableDates = generateDates(startDate, endDate);
  
  const params = new URLSearchParams(window.location.search);
  const initialDate = params.get('selectedDate') || availableDates[0];

  const [dayMonthYear, setDayMonthYear] = useState(initialDate);
  const [newsData, setNewsData] = useState([]);

  const [pageIdDisplayed, setPageIdDisplayed] = useState(null);
  const [articleIdDisplayed, setArticleIdDisplayed] = useState(null);

  const [isLoadingArticles, setIsLoadingArticles] = useState(false);

  
  useEffect(() => {
    params.set('selectedDate', dayMonthYear);
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
  }, [dayMonthYear]);
  

  return (
    <Row style={{backgroundColor: 'white'}}>
      <Col span={5} className="rightBoxShadow">
        <NewsDays 
          dayMonthYear={dayMonthYear} 
          setDayMonthYear={setDayMonthYear}
          datesData={availableDates}
        />
      </Col>
      <Col span={10} className="rightBoxShadow " style={{
          height: "calc(100vh - 64px)",
          overflow: 'auto'}}> 
        <div className='ml-6 mt-4 mr-6'>
          <Articles 
            dayMonthYear={dayMonthYear} 
            newsData={newsData} 
            setNewsData={setNewsData} 
            setPageIdDisplayed={setPageIdDisplayed} 
            setArticleIdDisplayed={setArticleIdDisplayed}
            setIsLoadingArticles={setIsLoadingArticles} 
          />
        </div>
      </Col>
      <Col span={9} style={{  }}>
        <div className='ml-6 mt-4 mr-6'>
          <ArticleMedia 
            dayMonthYear={dayMonthYear} 
            newsData={newsData} 
            pageIdDisplayed={pageIdDisplayed} 
            articleIdDisplayed={articleIdDisplayed}
            isLoadingArticles={isLoadingArticles} 
          />
        </div>
      </Col>
    </Row>
    
  );
}

export default Home;