import { useEffect, useState } from "react";
import logo from '../logo.svg';
import '../App.css';
import { Button } from 'antd';
import 'antd/dist/reset.css';
import { Col, Row, Input, Spin } from 'antd';
import { SmileOutlined, LoadingOutlined } from '@ant-design/icons';
import Articles from '../components/Articles'
import NewsDays from '../components/NewsDays';
import ArticleMedia from '../components/ArticleMedia';
import { db } from '../firebase'; // update with your path to firestore config
import { collection, getDocs } from "firebase/firestore";
import dates from "../utilities/dates";
// import {getBigQueryData} from '../utilities/getBigQueryData'
import { useLocation } from 'react-router-dom';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { searchArticles } from '../utilities/database';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function Search() {

  const [newsData, setNewsData] = useState([]);

  const params = new URLSearchParams(window.location.search);
  const initialDate = params.get('selectedDate');
  
  const [dayMonthYear, setDayMonthYear] = useState(initialDate);

  useEffect(() => {
    params.set('selectedDate', dayMonthYear);
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    // console.log('dayMonthYear now became: ', dayMonthYear)
  }, [dayMonthYear]);
  
  const [loading, setLoading] = useState(false);
  const [newsDataFromSearch, setNewsDataFromSearch] = useState([]);
  const [daysWithDataFound, setDaysWithDataFound] = useState([]);
  
  const [searchTerm, setSearchTerm] = useState(null);
  const [pageIdDisplayed, setPageIdDisplayed] = useState(null);
  const [articleIdDisplayed, setArticleIdDisplayed] = useState(null);
  const [isLoadingArticles, setIsLoadingArticles] = useState(false);

  const [searchType, setSearchType] = useState('exact');

  const query = useQuery();
  const searchUrlParam = params.get('searchTerm');
  const searchTypeParam = params.get('searchType');

  useEffect(() => {
    const urlSearchTerm = query.get('searchTerm');
    const urlSearchType = query.get('searchType') || 'exact';
    if (urlSearchTerm) {
      setSearchTerm(decodeURIComponent(urlSearchTerm));
      setSearchType(urlSearchType);
    }
  }, [query]);

  useEffect(() => {
    if (searchTerm && searchType) {
      performSearch(searchTerm);
    }
  }, [searchTerm, searchType]);
  

  const datesrange = ['1922-01-15', '1995-01-18'];
  let dateStart = datesrange[0];
  let dateEnd = datesrange[1];

  const queryToApply = `
  #standardsql
  SELECT 
      document_id
      -- , json_extract(data, '$.date') as date
      , json_extract_scalar(data, '$.day') as day
      -- , json_extract(data, '$.response.docs') as data
      , json_extract_scalar(doc, '$.fullTitle') as fullTitle
      , json_extract_scalar(doc, '$.abstractText') as abstractText
      , json_extract_scalar(doc, '$.articleID') as articleID
      , json_extract_scalar(doc, '$.pageID') as pageID
      , json_extract_scalar(doc, '$.pageNum') as pageNum
  FROM \`${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firestore_export.newspapers_pages_raw_latest\`
  left join unnest(json_extract_array(json_extract(data, '$.response.docs'))) as doc
  
  -- USE THIS IF ALSO "FENDINEBBIA" OR "OFFENDI" ARE OK TO HAVE AS RESULTS
  -- where LOWER(json_extract_scalar(doc, '$.abstractText')) LIKE '%fendi%'
  
  -- USE THIS IF ONLY 'FENDI' SHOULD BE RETURNED
  -- WHERE json_extract(data, '$.date') between Date('1922-01-15') and Date('1922-01-18')
  WHERE Date(json_extract_scalar(data, '$.day')) between Date('${dateStart}') and Date('${dateEnd}')
  and (
    REGEXP_CONTAINS(LOWER(json_extract_scalar(doc, '$.abstractText')), r'\\b${searchTerm}\\b')
    OR REGEXP_CONTAINS(LOWER(json_extract_scalar(doc, '$.fullTitle')), r'\\b${searchTerm}\\b')
  )
  
  order by day ASC
`;

const dummyData = [
  {
      "document_id": "1172_01_1922_0014_0002_pagedata",
      "day": "1922-01-17",
      "abstractText": "\nNuova Associazione fra Agricoltori a Novara Novara, io notte. ta Associazione fra proprietari e conduttori di fondi del Novarese hi 6, col nuovo anno, sciolta, ncr dar adito alla ricostituzione di tre distinte società- proprietari, conduttori al fendi e piccoli r.'&lt;i tiri ci ari ed affittuari...",
      "articleID": "1172_01_1922_0014_0002_24321175",
      "pageID": "1172_01_1922_0014_0002",
      "pageNum": "2"
  },
  {
      "document_id": "0041_01_1947_0031_0002_pagedata",
      "day": "1947-02-07",
      "abstractText": "\n. aro. rasarne» SC//TOPP/ RRLORIO.figli TORINO-RIVO LI ft« fendi t* -w 092 A V VISO L'Istituto Riposo Vecchiaia Torino già Ospizio di Carità) vendo 11 16 corrente febbraio alle ore 16,30 negli uffici di via Po 29, a licitazione privata ad offerte segrete ed a corpo i seguenti appezzamenti di ter...",
      "articleID": "0041_01_1947_0031_0002_24630868",
      "pageID": "0041_01_1947_0031_0002",
      "pageNum": "2"
  },
  {
      "document_id": "0113_01_1967_0002_0014_pagedata",
      "day": "1967-01-04",
      "abstractText": "\nesperto di questioni finanzia ri i, avrebbe assunto il dicaste ro dell'Economia. Sebbene la Svizzera pratichi da secoli una politica dì neu tralità, il governo di Berna non ha mai trascurato la dife sa nazionale, stanziando enor mi fendi per gli acquisti di moderni armamenti Da qual che anno qua...",
      "articleID": "0113_01_1967_0002_0014_6627783",
      "pageID": "0113_01_1967_0002_0014",
      "pageNum": "14"
  }
]

  const extractDates = (data) => {
    const datesSet = new Set(data.map((item) => {
      // Extract date from page_id (e.g., "1986-02-08_0988_01_1986_0033_0015_pagedata")
      const dateFromId = item.page_id.split('_')[0];
      // console.log('Extracted date:', dateFromId);
      return dateFromId;
    }));
    return Array.from(datesSet);
  };

  

const performSearch = async (searchTerm) => {
  if (!searchTerm) {
    console.log('No search term provided');
    return;
  }

  setNewsDataFromSearch(null);
  setLoading(true);

  try {
    console.log('Performing search with:', { searchTerm, searchType });
    const results = await searchArticles(searchTerm, searchType);
    console.log('Search results from Postgres:', results);
    
    const datesArray = extractDates(results);
    console.log('Dates with results:', datesArray);
    
    setDaysWithDataFound(datesArray);
    setNewsDataFromSearch(results);
  } catch (error) {
    console.error('Error performing search:', error);
  } finally {
    setLoading(false);
  }
};

  // Add effect to watch for localStorage changes
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'searchDateRange' && searchTerm) {
        performSearch(searchTerm);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [searchTerm]);

  // Also watch localStorage directly since the storage event doesn't fire in the same window
  useEffect(() => {
    if (searchTerm) {
      performSearch(searchTerm);
    }
  }, [localStorage.getItem('searchDateRange')]);

  return (
    <Row style={{backgroundColor: 'white'}}>

      {searchTerm ? (
        loading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 'calc(100vh - 100px)',
              width: '100%',
            }}
          >
            <Spin 
              tip="Fetching data, please wait..." 
              style={{ color: 'black' }}
              indicator={<LoadingOutlined style={{ fontSize: 24, color: 'black' }} />}
             />
          </div>
        ) : (
          <>
          <Col span={5} className="rightBoxShadow">
            <NewsDays dayMonthYear={dayMonthYear} setDayMonthYear={setDayMonthYear} datesData={daysWithDataFound} showDaysWithDataFound={true}/>
          </Col>
          <Col span={10} className="rightBoxShadow " style={{
              height: "calc(100vh - 64px)",
              overflow: 'auto'}}> 
            <div className='ml-6 mt-6 mr-6'>
              <Articles dayMonthYear={dayMonthYear} newsData={newsData} setNewsData={setNewsData} newsDataFromSearch={newsDataFromSearch} setPageIdDisplayed={setPageIdDisplayed} setArticleIdDisplayed={setArticleIdDisplayed} searchTerm={searchTerm} setIsLoadingArticles={setIsLoadingArticles} />
            </div>
          </Col>
          <Col span={9} style={{  }}>
            <div className='ml-6 mt-6 mr-6'>
              <ArticleMedia dayMonthYear={dayMonthYear} newsData={newsData} pageIdDisplayed={pageIdDisplayed} articleIdDisplayed={articleIdDisplayed} isLoadingArticles={isLoadingArticles} />
            </div>
          </Col>

          {/* {
                newsDataFromSearch && 
                newsDataFromSearch.map((a) => (
                    <>
                    <p>{a.day}</p>
                    <p>{a.document_id}</p>
                    <p>{a.abstractText}</p>
                    </>
                  ))
            } */}
          </>
        )
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(100vh - 100px)',
            width: '100%',
          }}
        >
          <SmileOutlined style={{ fontSize: 20, marginBottom: 10 }} />
          <p>Start a search above</p>
        </div>
      )}
      
        {/* <Button type="primary" onClick={performSearch} style={{ width: '100%' }}>
              Login
            </Button>
            {
                daysWithDataFound && 
                daysWithDataFound.map((a) => (
                    <>
                    <p>{a.day}</p>
                    <p>{a.document_id}</p>
                    <p>{a.abstractText}</p>
                    </>
                  ))
            } */}


      {/* <Col span={5} className="rightBoxShadow">
        <NewsDays dayMonthYear={dayMonthYear} setDayMonthYear={setDayMonthYear}/>
      </Col>
      <Col span={10} className="rightBoxShadow " style={{
          height: "calc(100vh - 64px)",
          overflow: 'auto'}}> 
        <div className='ml-6 mt-6 mr-6'>
          <Articles dayMonthYear={dayMonthYear} newsData={newsData} setNewsData={setNewsData} />
        </div>
      </Col>
      <Col span={9} style={{  }}>
        <div className='ml-6 mt-6 mr-6'>
          <ArticleMedia newsData={newsData} />
        </div>
      </Col> */}
    </Row>
    
  );
}

export default Search;