import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import {
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
} from '../components/widgets/AntLayout';

const queryPostgres = httpsCallable(functions, 'queryPostgres');

/**
 * Execute a Postgres query safely with error handling
 */
const executeQuery = async (query, params = []) => {
  try {
    console.log('Executing query:', query, 'Params:', params);
    const result = await queryPostgres({ query, params });
    return result.data.rows;
  } catch (error) {
    console.error('Database query failed:', error);
    return [];
  }
};

/**
 * Fetch articles by date with pagination support
 */
export const fetchArticlesByDate = async (date, pageNumber = 1) => {
  return executeQuery(
    `SELECT * FROM articles WHERE date = $1 AND page_number = $2`,
    [date, pageNumber],
  );
};

/**
 * Search articles with different search strategies
 */
export const searchArticles = async (searchTerm, searchType = 'broad') => {
  if (!searchTerm) {
    console.error('Search term is required');
    return [];
  }

  // Get date range from localStorage
  const savedRange = localStorage.getItem('searchDateRange');
  const [startDate, endDate] = savedRange
    ? JSON.parse(savedRange)
    : [DEFAULT_START_DATE, DEFAULT_END_DATE];

  console.log('Search Type:', searchType);
  console.log('Search Term:', searchTerm);
  console.log('Date Range:', startDate, 'to', endDate);

  const dateRangeClause = `AND date BETWEEN $2 AND $3`;

  let query;
  let params;

  switch (searchType) {
    case 'broadest':
      // ✅ Broadest search using Regex (~*)
      query = `
        SELECT * FROM articles 
        WHERE (abstract_text ~* $1 OR full_title ~* $1)
        ${dateRangeClause}
        ORDER BY date ASC;
      `;
      params = [`${searchTerm}`, startDate, endDate];
      break;

    case 'broad':
      // ✅ Broad search using ILIKE %WORD%
      query = `
        SELECT * FROM articles 
        WHERE (abstract_text ILIKE $1 OR full_title ILIKE $1)
        ${dateRangeClause}
        ORDER BY date ASC;
      `;
      params = [`%${searchTerm}%`, startDate, endDate];
      break;

    case 'exact':
      // ✅ Exact Match (With Stemming) - Uses 'italian' index
      query = `
        SELECT * FROM articles 
        WHERE to_tsvector('italian', abstract_text || ' ' || full_title) @@ plainto_tsquery('italian', $1)
        ${dateRangeClause}
        ORDER BY date ASC;
      `;
      params = [searchTerm, startDate, endDate];
      break;

    case 'strictExact':
      // ✅ Strict Exact Match (No Stemming) - Uses 'simple' index
      query = `
        SELECT * FROM articles 
        WHERE to_tsvector('simple', abstract_text || ' ' || full_title) @@ to_tsquery('simple', $1)
        ${dateRangeClause}
        ORDER BY date ASC;
      `;
      params = [searchTerm, startDate, endDate];
      break;

    default:
      console.error('Invalid search type');
      return [];
  }

  return executeQuery(query, params);
};

/**
 * Fetch a specific article by ID
 */
export const getArticleById = async (id) => {
  return executeQuery(`SELECT * FROM newspaper_pages WHERE id = $1`, [id]);
};
