import React, { useState, useEffect } from 'react';
import NewsTile from './NewsTile';
import { Skeleton, Empty } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { fetchArticlesByDate } from '../utilities/database';

const Articles = ({
  dayMonthYear,
  newsDataFromSearch,
  setPageIdDisplayed,
  setArticleIdDisplayed,
  searchTerm,
  setIsLoadingArticles,
}) => {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [dayMonthYear]);

  useEffect(() => {
    const fetchNews = async () => {
      setLoading(true);
      setIsLoadingArticles(true);
      try {
        let fetchedArticles = [];
        if (newsDataFromSearch) {
          fetchedArticles = newsDataFromSearch.filter(
            (item) => item.date === dayMonthYear,
          );
        } else {
          fetchedArticles = await fetchArticlesByDate(
            dayMonthYear,
            currentPage,
          );
        }

        setArticles(fetchedArticles);

        // Set the first article's IDs if we have articles
        if (fetchedArticles.length > 0) {
          // Group articles by page number
          const articlesByPage = fetchedArticles.reduce((acc, article) => {
            if (!acc[article.page_number]) {
              acc[article.page_number] = [];
            }
            acc[article.page_number].push(article);
            return acc;
          }, {});

          // Get the first article of the current page
          const firstArticle = fetchedArticles[0];
          const firstArticleOfPage =
            articlesByPage[firstArticle.page_number][0];

          setPageIdDisplayed(firstArticle.page_number);
          setArticleIdDisplayed(firstArticleOfPage.article_id);
        } else {
          setPageIdDisplayed(null);
          setArticleIdDisplayed(null);
        }
      } catch (error) {
        console.error('Error fetching news:', error);
        setPageIdDisplayed(null);
        setArticleIdDisplayed(null);
      }
      setLoading(false);
      setIsLoadingArticles(false);
    };

    fetchNews();
  }, [dayMonthYear, newsDataFromSearch, currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton
            key={index}
            style={{ width: 300, height: 200, margin: 16 }}
            active
          />
        ))}
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 16,
          justifyContent: 'space-between',
        }}>
        <h4 style={{ margin: 0 }}>Articles</h4>
        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          <LeftOutlined
            onClick={handlePrevPage}
            style={{
              cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
              opacity: currentPage > 1 ? 1 : 0.5,
            }}
          />
          <span>Page {currentPage}</span>
          <RightOutlined
            onClick={handleNextPage}
            style={{
              cursor: articles.length > 0 ? 'pointer' : 'not-allowed',
              opacity: articles.length > 0 ? 1 : 0.5,
            }}
          />
        </div>
      </div>

      {!articles.length ? (
        <Empty />
      ) : (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {articles.map((article) => (
            <NewsTile
              key={article.article_id}
              newsData={{
                ...article,
                articleID: article.article_id,
                pageNum: article.page_number,
              }}
              setPageIdDisplayed={setPageIdDisplayed}
              setArticleIdDisplayed={setArticleIdDisplayed}
              searchTerm={searchTerm}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default Articles;
