import { useState, useEffect, useRef } from 'react';
import { Avatar, Divider, List, Affix, Skeleton } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { generateDates } from '../utilities/dates';
import {
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
} from '../components/widgets/AntLayout';

const NewsDays = ({
  dayMonthYear,
  setDayMonthYear,
  datesData,
  showDaysWithDataFound,
}) => {
  const dates =
    datesData || generateDates(DEFAULT_START_DATE, DEFAULT_END_DATE);

  const handlePrevDate = () => {
    const currentIndex = dates.indexOf(dayMonthYear);
    if (currentIndex > 0) {
      setDayMonthYear(dates[currentIndex - 1]);
    }
  };

  const handleNextDate = () => {
    const currentIndex = dates.indexOf(dayMonthYear);
    if (currentIndex < dates.length - 1) {
      setDayMonthYear(dates[currentIndex + 1]);
    }
  };

  return (
    <Affix>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '16px',
            borderBottom: '1px solid #f0f0f0',
          }}>
          <h4 style={{ margin: 0 }}>
            News Days
            {showDaysWithDataFound &&
              datesData?.length > 0 &&
              ` (${datesData.length} days)`}
          </h4>
          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <LeftOutlined
              onClick={handlePrevDate}
              style={{
                cursor:
                  dates.indexOf(dayMonthYear) > 0 ? 'pointer' : 'not-allowed',
                opacity: dates.indexOf(dayMonthYear) > 0 ? 1 : 0.5,
              }}
            />
            <RightOutlined
              onClick={handleNextDate}
              style={{
                cursor:
                  dates.indexOf(dayMonthYear) < dates.length - 1
                    ? 'pointer'
                    : 'not-allowed',
                opacity:
                  dates.indexOf(dayMonthYear) < dates.length - 1 ? 1 : 0.5,
              }}
            />
          </div>
        </div>
        <List
          dataSource={dates}
          renderItem={(date) => (
            <List.Item
              onClick={() => setDayMonthYear(date)}
              style={{
                background: dayMonthYear === date ? '#e8e8e8' : 'white',
              }}>
              {date}
            </List.Item>
          )}
          style={{ height: 'calc(100vh - 120px)', overflowY: 'auto' }}
        />
      </div>
    </Affix>
  );
};

export default NewsDays;
