import { useState, useEffect } from 'react';
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Input,
  Modal,
  Radio,
  Button,
  DatePicker,
} from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import { getAuth, signOut } from 'firebase/auth';
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import UserMenu from './UserMenu';
import dayjs from 'dayjs';

const { Header, Content, Footer } = Layout;
const { RangePicker } = DatePicker;

export const DEFAULT_START_DATE = '1940-01-01';
export const DEFAULT_END_DATE = '2005-12-31';
export const MIN_DATE = '1915-01-01';
export const MAX_DATE = '2005-12-31';

const items = [
  {
    label: 'Home',
    key: 'home',
  },
  {
    label: 'Favorites',
    key: 'favorites',
  },
];

const AntLayout = ({ children }) => {
  const params = new URLSearchParams(window.location.search);
  const initialSearchTerm = params.get('searchTerm');

  const Search = Input.Search;

  const [current, setCurrent] = useState('home');
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchType, setSearchType] = useState('exact');
  const [dateRange, setDateRange] = useState(() => {
    const saved = localStorage.getItem('searchDateRange');
    if (saved) {
      return JSON.parse(saved);
    }
    return [DEFAULT_START_DATE, DEFAULT_END_DATE];
  });

  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSearchClick = (searchTerm) => {
    navigate(
      `/search?searchTerm=${encodeURIComponent(
        searchTerm,
      )}&searchType=${searchType}`,
    );
    setIsModalOpen(false);
  };

  const onMenuClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
    navigate(`/${e.key}`);
  };

  const handleDateRangeChange = (dates) => {
    if (!dates) return;
    const [start, end] = dates;
    const newRange = [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')];
    setDateRange(newRange);
    localStorage.setItem('searchDateRange', JSON.stringify(newRange));

    // If we're on the search page, update the URL with current search params
    if (window.location.pathname === '/search') {
      const currentParams = new URLSearchParams(window.location.search);
      navigate(
        `/search?searchTerm=${currentParams.get(
          'searchTerm',
        )}&searchType=${currentParams.get('searchType')}`,
        { replace: true },
      );
    }
  };

  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          backgroundColor: 'white',
          boxShadow: '0px 0px 8px rgba(0,0,0,.4)',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <div
            style={{
              fontSize: '1.5em',
              fontWeight: 'bold',
              marginLeft: '0px',
              marginRight: '30px',
            }}>
            News Archive
          </div>
          <Menu
            onClick={onMenuClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <RangePicker
            value={[dayjs(dateRange[0]), dayjs(dateRange[1])]}
            onChange={handleDateRangeChange}
            disabledDate={(current) => {
              const date = current.format('YYYY-MM-DD');
              return date < MIN_DATE || date > MAX_DATE;
            }}
            style={{ marginRight: 10 }}
          />
          <Search
            placeholder="type here"
            style={{ width: 200, marginRight: 10 }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onSearch={onSearchClick}
            onClick={showModal}
            readOnly
          />
          <UserMenu />
        </div>
      </Header>
      <Modal
        title="Advanced Search"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Search
            placeholder="Enter search term"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onSearch={onSearchClick}
            enterButton="Search"
            size="large"
          />

          <Radio.Group
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Radio value="broadest">Broadest search (regex)</Radio>
            <Radio value="broad">Broad search (ilike)</Radio>
            <Radio value="exact">Word match (with stemming)</Radio>
            <Radio value="strictExact">Strict word match (no stemming)</Radio>
          </Radio.Group>
        </div>
      </Modal>
      <Content className="site-layout" style={{ padding: '0px' }}>
        {children}
      </Content>
    </Layout>
  );
};

export default AntLayout;
