const generateDates = (startDateStr, endDateStr) => {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  let dates = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    let dateString = currentDate.toISOString().split('T')[0];
    dates.push(dateString);
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }

  return dates;
};

export { generateDates };
