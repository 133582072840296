import React, { useState, useEffect } from 'react';
import { Switch, Skeleton, Image } from 'antd';

const ArticleMedia = ({
  dayMonthYear,
  newsData,
  pageIdDisplayed,
  articleIdDisplayed,
  isLoadingArticles,
}) => {
  console.log('ArticleMedia props:', {
    dayMonthYear,
    pageIdDisplayed,
    articleIdDisplayed,
    newsDataLength: newsData?.length,
  });

  // new url uses cloud function to get image from http and serves as https
  const url = `https://us-central1-news-archive-28cc7.cloudfunctions.net/getImageFromUrl?imageId=${articleIdDisplayed}`;
  console.log('Generated image URL:', url);

  // url to open the lastampa website on a specific page
  const urlNewspaperArchive = `http://www.archiviolastampa.it/component/option,com_lastampa/task,search/mod,libera/action,viewer/Itemid,3/page,${pageIdDisplayed}/articleid,${articleIdDisplayed}/anews,true/`;
  console.log('Generated newspaper URL:', urlNewspaperArchive);

  const openSource = () => {
    console.log(`opening!`);
    window.open(urlNewspaperArchive, '_blank');
  };

  const [switchOn, setSwitchOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChangingPage, setIsChangingPage] = useState(false);

  const handleSwitchChange = (checked) => {
    setSwitchOn(checked);
    setLoading(true);
  };

  // Handle page changes
  useEffect(() => {
    if (switchOn) {
      setIsChangingPage(true);
      setLoading(true);
    }
  }, [pageIdDisplayed, articleIdDisplayed]);

  useEffect(() => {
    if (switchOn) {
      setLoading(true);
      console.log('Loading new image with:', {
        articleIdDisplayed,
        pageIdDisplayed,
        dayMonthYear,
      });
    }
  }, [articleIdDisplayed, pageIdDisplayed, dayMonthYear, switchOn]);

  const handleImageLoad = () => {
    setLoading(false);
    setIsChangingPage(false);
  };

  useEffect(() => {
    console.log('Loading state changed:', loading);
  }, [loading]);

  const disabled = !pageIdDisplayed || !articleIdDisplayed || !newsData;

  return (
    <>
      <div>
        <span style={{ textDecoration: 'underline' }} onClick={openSource}>
          Open in LaStampa
        </span>
        <span className="ml-4">
          <Switch
            checked={switchOn}
            disabled={disabled}
            onChange={handleSwitchChange}
            style={{ backgroundColor: switchOn ? 'black' : '#c7c7c7' }}
          />
        </span>
      </div>

      {switchOn && !disabled && (
        <div className="mt-4">
          {(loading || isLoadingArticles) && (
            <Skeleton.Image style={{ width: 400, height: 600 }} active />
          )}
          <Image
            key={articleIdDisplayed}
            src={url}
            style={{ display: loading || isLoadingArticles ? 'none' : 'block' }}
            onLoad={handleImageLoad}
          />
        </div>
      )}
    </>
  );
};

export default ArticleMedia;
